/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript+abap+actionscript+ada+apacheconf+apl+applescript+c+arff+asciidoc+asm6502+csharp+autohotkey+autoit+bash+basic+batch+bison+brainfuck+bro+cpp+aspnet+arduino+coffeescript+clojure+ruby+csp+css-extras+d+dart+diff+django+docker+eiffel+elixir+elm+markup-templating+erlang+fsharp+flow+fortran+gedcom+gherkin+git+glsl+gml+go+graphql+groovy+less+handlebars+haskell+haxe+http+hpkp+hsts+ichigojam+icon+inform7+ini+io+j+java+jolie+json+julia+keyman+kotlin+latex+markdown+liquid+lisp+livescript+lolcode+lua+makefile+crystal+erb+matlab+mel+mizar+monkey+n4js+nasm+nginx+nim+nix+nsis+objectivec+ocaml+opencl+oz+parigp+parser+pascal+perl+php+php-extras+sql+powershell+processing+prolog+properties+protobuf+scss+puppet+pure+python+q+qore+r+jsx+typescript+renpy+reason+rest+rip+roboconf+textile+rust+sas+sass+stylus+scala+scheme+smalltalk+smarty+plsql+soy+pug+swift+yaml+tcl+haml+tt2+twig+tsx+vbnet+velocity+verilog+vhdl+vim+visual-basic+wasm+wiki+xeora+xojo+xquery+tap&plugins=line-highlight+line-numbers+toolbar+command-line+show-language+copy-to-clipboard */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

 code[class*="language-"],
 pre[class*="language-"] {
   color: #ccc;
   background: none;
   font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
   text-align: left;
   white-space: pre;
   word-spacing: normal;
   word-break: normal;
   word-wrap: normal;
   line-height: 1.5;
 
   -moz-tab-size: 4;
   -o-tab-size: 4;
   tab-size: 4;
 
   -webkit-hyphens: none;
   -moz-hyphens: none;
   -ms-hyphens: none;
   hyphens: none;
 
 }
 
 /* Code blocks */
 pre[class*="language-"] {
   padding: 1em;
   margin: .5em 0;
   overflow: auto;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
   background: #2d2d2d;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
   padding: .1em;
   border-radius: .3em;
   white-space: normal;
 }
 
 .token.comment,
 .token.block-comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
   color: #999;
 }
 
 .token.punctuation {
   color: #ccc;
 }
 
 .token.tag,
 .token.attr-name,
 .token.namespace,
 .token.deleted {
   color: #e2777a;
 }
 
 .token.function-name {
   color: #6196cc;
 }
 
 .token.boolean,
 .token.number,
 .token.function {
   color: #f08d49;
 }
 
 .token.property,
 .token.class-name,
 .token.constant,
 .token.symbol {
   color: #f8c555;
 }
 
 .token.selector,
 .token.important,
 .token.atrule,
 .token.keyword,
 .token.builtin {
   color: #cc99cd;
 }
 
 .token.string,
 .token.char,
 .token.attr-value,
 .token.regex,
 .token.variable {
   color: #7ec699;
 }
 
 .token.operator,
 .token.entity,
 .token.url {
   color: #67cdcc;
 }
 
 .token.important,
 .token.bold {
   font-weight: bold;
 }
 .token.italic {
   font-style: italic;
 }
 
 .token.entity {
   cursor: help;
 }
 
 .token.inserted {
   color: green;
 }
 
 pre[data-line] {
   position: relative;
   padding: 1em 0 1em 3em;
 }
 
 .line-highlight {
   position: absolute;
   left: 0;
   right: 0;
   padding: inherit 0;
   margin-top: 1em; /* Same as .prism’s padding-top */
 
   background: hsla(24, 20%, 50%,.08);
   background: linear-gradient(to right, hsla(24, 20%, 50%,.1) 70%, hsla(24, 20%, 50%,0));
 
   pointer-events: none;
 
   line-height: inherit;
   white-space: pre;
 }
 
   .line-highlight:before,
   .line-highlight[data-end]:after {
     content: attr(data-start);
     position: absolute;
     top: .4em;
     left: .6em;
     min-width: 1em;
     padding: 0 .5em;
     background-color: hsla(24, 20%, 50%,.4);
     color: hsl(24, 20%, 95%);
     font: bold 65%/1.5 sans-serif;
     text-align: center;
     vertical-align: .3em;
     border-radius: 999px;
     text-shadow: none;
     box-shadow: 0 1px white;
   }
 
   .line-highlight[data-end]:after {
     content: attr(data-end);
     top: auto;
     bottom: .4em;
   }
 
 .line-numbers .line-highlight:before,
 .line-numbers .line-highlight:after {
   content: none;
 }
 
 pre[class*="language-"].line-numbers {
   position: relative;
   padding-left: 3.8em;
   counter-reset: linenumber;
 }
 
 pre[class*="language-"].line-numbers > code {
   position: relative;
   white-space: inherit;
 }
 
 .line-numbers .line-numbers-rows {
   position: absolute;
   pointer-events: none;
   top: 0;
   font-size: 100%;
   left: -3.8em;
   width: 3em; /* works for line-numbers below 1000 lines */
   letter-spacing: -1px;
   border-right: 1px solid #999;
 
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 
 }
 
   .line-numbers-rows > span {
     pointer-events: none;
     display: block;
     counter-increment: linenumber;
   }
 
     .line-numbers-rows > span:before {
       content: counter(linenumber);
       color: #999;
       display: block;
       padding-right: 0.8em;
       text-align: right;
     }
 
 div.code-toolbar {
   position: relative;
 }
 
 div.code-toolbar > .toolbar {
   position: absolute;
   top: .3em;
   right: .2em;
   transition: opacity 0.3s ease-in-out;
   opacity: 0;
 }
 
 div.code-toolbar:hover > .toolbar {
   opacity: 1;
 }
 
 div.code-toolbar > .toolbar .toolbar-item {
   display: inline-block;
 }
 
 div.code-toolbar > .toolbar a {
   cursor: pointer;
 }
 
 div.code-toolbar > .toolbar button {
   background: none;
   border: 0;
   color: inherit;
   font: inherit;
   line-height: normal;
   overflow: visible;
   padding: 0;
   -webkit-user-select: none; /* for button */
   -moz-user-select: none;
   -ms-user-select: none;
 }
 
 div.code-toolbar > .toolbar a,
 div.code-toolbar > .toolbar button,
 div.code-toolbar > .toolbar span {
   color: #bbb;
   font-size: .8em;
   padding: 0 .5em;
   background: #f5f2f0;
   background: rgba(224, 224, 224, 0.2);
   box-shadow: 0 2px 0 0 rgba(0,0,0,0.2);
   border-radius: .5em;
 }
 
 div.code-toolbar > .toolbar a:hover,
 div.code-toolbar > .toolbar a:focus,
 div.code-toolbar > .toolbar button:hover,
 div.code-toolbar > .toolbar button:focus,
 div.code-toolbar > .toolbar span:hover,
 div.code-toolbar > .toolbar span:focus {
   color: inherit;
   text-decoration: none;
 }
 
 .command-line-prompt {
   border-right: 1px solid #999;
   display: block;
   float: left;
   font-size: 100%;
   letter-spacing: -1px;
   margin-right: 1em;
   pointer-events: none;
 
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 .command-line-prompt > span:before {
   color: #999;
   content: ' ';
   display: block;
   padding-right: 0.8em;
 }
 
 .command-line-prompt > span[data-user]:before {
   content: "[" attr(data-user) "@" attr(data-host) "] $";
 }
 
 .command-line-prompt > span[data-user="root"]:before {
   content: "[" attr(data-user) "@" attr(data-host) "] #";
 }
 
 .command-line-prompt > span[data-prompt]:before {
   content: attr(data-prompt);
 }
 