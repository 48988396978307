// Fonts
$font-primary: 'Karla', sans-serif;

// Colors
$color-accent: #006dd2;
$gray: #a1a1a7;
$gray-dark: #535358;
$gray-darkest: #2f313d;
$gray-light: #d8d8d8;
$gray-lighter: #e5e7e9;
$gray-lightest: #f9f9fb;
$yellow: #fffac4;
